import { Bold, Italic, Link, Underline } from "lucide-react";
import type React from "react";
import { useEffect, useRef } from "react";

interface TextEditorProps {
	initialContent?: string;
	onChange?: (content: string) => void;
	className?: string;
	placeholder?: string;
}

export function TextEditor({
	initialContent = "",
	onChange,
	className = "",
	placeholder = "Start typing...",
}: TextEditorProps) {
	const editorRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (editorRef.current) {
			editorRef.current.innerHTML = initialContent || placeholder;
			modifyExistingLinks();
		}
	}, [initialContent, placeholder]);

	const formatText = (command: string) => {
		document.execCommand(command, false);
		updateContent();
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Enter") {
			e.preventDefault();
			document.execCommand("insertLineBreak", false);
		}
	};

	const updateContent = () => {
		if (editorRef.current) {
			const newContent = editorRef.current.innerHTML;
			if (newContent !== placeholder) {
				modifyExistingLinks();
				onChange?.(newContent);
			}
		}
	};

	const modifyExistingLinks = () => {
		if (editorRef.current) {
			const links = editorRef.current.getElementsByTagName("a");
			for (let i = 0; i < links.length; i++) {
				links[i].target = "_blank";
				links[i].rel = "noopener noreferrer";
				links[i].className = "text-primary-600";
			}
		}
	};

	const handleFocus = () => {
		if (editorRef.current && editorRef.current.innerHTML === placeholder) {
			editorRef.current.innerHTML = "";
		}
	};

	const handleBlur = () => {
		if (editorRef.current && editorRef.current.innerHTML.trim() === "") {
			editorRef.current.innerHTML = placeholder;
		}
		updateContent();
	};

	return (
		<div
			className={`w-full rounded-[8px] border border-solid border-neutral-200 p-4 text-neutral-900 ${className}`}
		>
			<div className="mb-4 flex items-center gap-2">
				<button
					className="rounded p-2 text-neutral-600 transition-all duration-300 hover:bg-neutral-200 hover:text-neutral-1100"
					onClick={() => formatText("bold")}
					type="button"
				>
					<Bold className="h-4 w-4" />
				</button>
				<button
					className="rounded p-2 text-neutral-600 transition-all duration-300 hover:bg-neutral-200 hover:text-neutral-1100"
					onClick={() => formatText("italic")}
					type="button"
				>
					<Italic className="h-4 w-4" />
				</button>
				<button
					className="rounded p-2 text-neutral-600 transition-all duration-300 hover:bg-neutral-200 hover:text-neutral-1100"
					onClick={() => formatText("underline")}
					type="button"
				>
					<Underline className="h-4 w-4" />
				</button>
				<div className="mx-2 h-6 w-px bg-neutral-200" />
				<button
					className="rounded p-2 text-neutral-600 transition-all duration-300 hover:bg-neutral-200 hover:text-neutral-1100"
					onClick={() => {
						let url = prompt("Enter URL:");
						if (url) {
							document.execCommand("createLink", false, "#temp-link#");
							const selection = window.getSelection();
							if (selection) {
								const range = selection.getRangeAt(0);
								const link = range.commonAncestorContainer.parentElement;
								if (link && link.tagName === "A") {
									if (!url.startsWith("https")) {
										url = `https://${url}`;
									}
									(link as HTMLAnchorElement).href = url;
									(link as HTMLAnchorElement).target = "_blank";
									(link as HTMLAnchorElement).rel = "noopener noreferrer";
									(link as HTMLAnchorElement).className = "text-primary-600";
								}
							}
							updateContent();
						}
					}}
					type="button"
				>
					<Link className="h-4 w-4" />
				</button>
			</div>

			<div
				ref={editorRef}
				contentEditable
				onKeyDown={handleKeyDown}
				onBlur={handleBlur}
				onFocus={handleFocus}
				className="min-h-[230px] space-y-6 text-[15px] leading-relaxed focus:outline-none [&_a]:text-primary-600"
			/>
		</div>
	);
}
