import { AxiosError } from "axios";

import { api } from "@/lib/axios";

import type {
	SaveHelpTopicError,
	SaveHelpTopicRequest,
	SaveHelpTopicResponse,
} from "./help-topics";

export async function saveHelpTopic(
	data: SaveHelpTopicRequest,
): Promise<
	| { status: "success"; data: SaveHelpTopicResponse }
	| { status: "error"; errors: SaveHelpTopicError }
> {
	try {
		const response = await api.post<SaveHelpTopicResponse>(
			"/help-topic/store",
			data,
		);

		return {
			status: "success",
			data: response.data,
		};
	} catch (error) {
		const axiosError = error as AxiosError<SaveHelpTopicError>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data,
			};
		}

		return {
			status: "error",
			errors: {
				message: "An unknown error occurred. Please try again later.",
				errors: {
					unknown: ["An unknown error occurred. Please try again later."],
				},
			},
		};
	}
}
