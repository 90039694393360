import { forwardRef, useState } from "react";

import ErrorIcon from "@/assets/error.svg?react";
import EyeIcon from "@/assets/eye.svg?react";
import EyeAltIcon from "@/assets/eye-alt.svg?react";
import { cn } from "@/lib/utils";

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	showPasswordIcon?: boolean;
	hasError?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
	({ className, type, showPasswordIcon, hasError, ...props }, ref) => {
		const [showPassword, setShowPassword] = useState(false);

		const togglePasswordVisibility = () => {
			setShowPassword(!showPassword);
		};

		return (
			<>
				<input
					type={showPassword ? "text" : type}
					className={cn(
						`flex h-14 w-full rounded-md border border-solid border-neutral-200 bg-neutral-100 px-4 py-1 font-inter text-P5 font-normal text-white ring-offset-background file:border-0 file:bg-transparent file:text-P5 file:font-medium placeholder:text-muted-foreground focus:border-primary-600 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 ${
							hasError ? "border-red-600 focus:border-red-600" : ""
						}`,
						className,
					)}
					ref={ref}
					{...props}
				/>

				{type === "password" && showPasswordIcon && (
					<button
						type="button"
						className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer border-none bg-transparent text-inherit"
						onClick={togglePasswordVisibility}
					>
						{showPassword ? <EyeAltIcon /> : <EyeIcon />}
					</button>
				)}

				{hasError && type !== "password" && (
					<span className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer border-none bg-transparent text-red-600">
						<ErrorIcon />
					</span>
				)}
			</>
		);
	},
);
Input.displayName = "Input";

export { Input };
