import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import {
	type Awards,
	fetchAwardsMonthlyRanking,
} from "@/api/admin/awards/monthly-ranking";
import { updateStrategy } from "@/api/admin/awards/update-strategy";
import { showUser, type User } from "@/api/admin/users/show";
import ChevronLeftIcon from "@/assets/chevron-left.svg?react";
import ChevronRightIcon from "@/assets/chevron-right.svg?react";
import MoreAltIcon from "@/assets/more-alt.svg?react";
import OptionsIcon from "@/assets/options.svg?react";
import PenIcon from "@/assets/pen.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import SmileMouthOpenIcon from "@/assets/smile-mouth-open.svg?react";
import TrophyIcon from "@/assets/trophy.svg?react";
import { AlertModal } from "@/components/alert-modal";
import { Modal } from "@/components/modal";
import { Tag } from "@/components/tag";
import { TextareaInput } from "@/components/textarea-input";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { useNavigationStore } from "@/store/navigation-store";
import { authorFallback } from "@/utils/mask-author-fallback";

import { AdminUsersModalProfile } from "../users/modal-profile";

const positionTrophyColor: { [key: number]: string } = {
	1: "#F1A22C",
	2: "#ACACAC",
	3: "#755B29",
	4: "#755B29",
	5: "#755B29",
};

export function AdminPersonalizationAwards() {
	const [openModal, setOpenModal] = useState(false);
	const [strategy, setStrategy] = useState("");
	const [month, setMonth] = useState<string>(
		new Date().toLocaleString("default", { month: "numeric" }),
	);
	const [year, setYear] = useState<string>(
		new Date().toLocaleString("default", { year: "numeric" }),
	);
	const [award, setAward] = useState<Awards | null>(null);
	const [currentUserData, setCurrentUserData] = useState<User | null>(null);
	const [filterIsOpen, setFilterIsOpen] = useState(false);
	const [isModalProfileOpen, setIsModalProfileOpen] = useState(false);
	const [currentAward, setCurrentAward] = useState<{
		id: string;
		position: number;
		name: string;
		image: string;
		strategy: string | null;
		total_sales: number;
		user_id: string;
	} | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [successModal, setSuccessModal] = useState(false);

	async function fetchRanking(month: string) {
		setIsLoading(true);

		const response = await fetchAwardsMonthlyRanking({
			month,
		});

		if (response.status === "success") {
			setAward(response.data);
		}

		setIsLoading(false);
	}

	async function handleShowUser(id: string) {
		setIsLoading(true);
		const result = await showUser(id);

		if (result.status === "success") {
			setCurrentUserData(result.data);
		}

		setIsLoading(false);
	}

	useEffect(() => {
		fetchRanking(
			`${new Date().toLocaleString("default", { year: "numeric" })}-${new Date().toLocaleString("default", { month: "numeric" }).padStart(2, "0")}`,
		);
	}, []);

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Configurações",
				path: "/admin",
			},
			{
				name: "Premiações",
				path: "/admin/awards",
			},
		];

		setPaths(paths);
	}, []);

	async function handleUpdateStrategy(strategy: string, id: string) {
		try {
			setIsLoading(true);

			const response = await updateStrategy({ strategy }, id);

			if (response.status === "success") {
				await fetchRanking(`${year}-${month}`);
				setOpenModal(false);
				setCurrentAward(null);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	const isMonthEnded = () => {
		const today = new Date();
		const selectedDate = new Date(parseInt(year), parseInt(month) - 1);
		return today > selectedDate && today.getMonth() !== selectedDate.getMonth();
	};

	return (
		<>
			<Helmet title="Premiações" />

			<div className="flex flex-col">
				<header className="flex items-center justify-between">
					<div className="flex items-center gap-4">
						<div className="flex items-center gap-4">
							<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
								{`${["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][parseInt(month) - 1] || "Selecione um mês"} ${year}`}
							</p>
						</div>
						<div className="flex items-center gap-0.5 text-neutral-1100">
							<button
								className="flex h-10 items-center justify-center gap-2 p-2.5 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:text-neutral-400"
								type="button"
								onClick={async () => {
									let dateMonth;
									let dateYear;

									const prevMonth = parseInt(month) - 1;
									if (prevMonth < 1) {
										setMonth("12");
										setYear((parseInt(year) - 1).toString());

										dateMonth = "12";
										dateYear = (parseInt(year) - 1).toString();
									} else {
										setMonth(prevMonth.toString().padStart(2, "0"));

										dateMonth = prevMonth.toString().padStart(2, "0");
										dateYear = year;
									}

									await fetchRanking(`${dateYear}-${dateMonth}`);
								}}
							>
								<ChevronLeftIcon width={18} height={18} />
							</button>
							<button
								className="flex h-10 items-center justify-center p-2.5 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:text-neutral-400"
								type="button"
								onClick={async () => {
									let dateMonth;
									let dateYear;

									const nextMonth = parseInt(month) + 1;
									if (nextMonth > 12) {
										setMonth("01");
										setYear((parseInt(year) + 1).toString());

										dateMonth = "01";
										dateYear = (parseInt(year) + 1).toString();
									} else {
										setMonth(nextMonth.toString().padStart(2, "0"));

										dateMonth = nextMonth.toString().padStart(2, "0");
										dateYear = year;
									}

									await fetchRanking(`${dateYear}-${dateMonth}`);
								}}
							>
								<ChevronRightIcon width={18} height={18} />
							</button>
						</div>
					</div>

					<button
						className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 px-6 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400"
						onClick={() => setFilterIsOpen(!filterIsOpen)}
					>
						<OptionsIcon />

						<p className="font-inter text-P6 font-semibold leading-160">
							Filtro
						</p>
					</button>
				</header>

				<div
					className={`flex max-h-0 flex-col gap-8 rounded-[10px] bg-neutral-100 bg-opacity-60 opacity-0 transition-all duration-500 ${
						filterIsOpen && "mt-12 max-h-fit p-6 opacity-100"
					}`}
				>
					{filterIsOpen && (
						<div className="flex flex-col gap-8">
							<div className="flex w-full flex-col items-center gap-6 md:flex-row">
								<Select
									onValueChange={(value) => setMonth(value)}
									defaultValue={month ? month : "Selecione um mês"}
									value={month ? month : "Selecione um mês"}
								>
									<SelectTrigger className="w-full">
										<SelectValue placeholder="Selecione um período" />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value="01">Janeiro</SelectItem>
										<SelectItem value="02">Fevereiro</SelectItem>
										<SelectItem value="03">Março</SelectItem>
										<SelectItem value="04">Abril</SelectItem>
										<SelectItem value="05">Maio</SelectItem>
										<SelectItem value="06">Junho</SelectItem>
										<SelectItem value="07">Julho</SelectItem>
										<SelectItem value="08">Agosto</SelectItem>
										<SelectItem value="09">Setembro</SelectItem>
										<SelectItem value="10">Outubro</SelectItem>
										<SelectItem value="11">Novembro</SelectItem>
										<SelectItem value="12">Dezembro</SelectItem>
									</SelectContent>
								</Select>

								<Select
									onValueChange={(value) => setYear(value)}
									defaultValue={year ? year : "Selecione um ano"}
									value={year ? year : "Selecione um ano"}
								>
									<SelectTrigger className="w-full">
										<SelectValue placeholder="Selecione um período" />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value="2024">2024</SelectItem>
										<SelectItem value="2025">2025</SelectItem>
										<SelectItem value="2026">2026</SelectItem>
										<SelectItem value="2027">2027</SelectItem>
										<SelectItem value="2028">2028</SelectItem>
										<SelectItem value="2029">2029</SelectItem>
										<SelectItem value="2030">2030</SelectItem>
									</SelectContent>
								</Select>
							</div>

							<div className="flex flex-col justify-between gap-6 md:flex-row md:items-center">
								<div>
									<span className="font-inter text-P5 font-normal leading-160 text-neutral-400">
										Nenhum filtro aplicado
									</span>
								</div>

								<div className="flex gap-4">
									<Button
										className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] bg-transparent font-inter !text-P6 font-semibold leading-160 text-neutral-600 transition-all duration-500 hover:bg-neutral-200 disabled:bg-transparent disabled:text-neutral-400"
										variant="secondary"
										onClick={() => {
											setMonth(
												new Date().toLocaleString("default", {
													month: "numeric",
												}),
											);
											setYear(
												new Date().toLocaleString("default", {
													year: "numeric",
												}),
											);

											setFilterIsOpen(false);
										}}
									>
										Limpar filtro
									</Button>
									<Button
										className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] font-inter !text-P6 font-semibold leading-160"
										onClick={() => {
											fetchRanking(`${year}-${month}`);

											setFilterIsOpen(false);
										}}
									>
										Aplicar
									</Button>
								</div>
							</div>
						</div>
					)}
				</div>

				{award && !isLoading && isMonthEnded() && (
					<section className="mt-12 flex flex-col gap-4">
						{award.ranking.slice(0, 5).map((award, index) => (
							<AwardCard
								key={award.id}
								position={index + 1}
								name={award.user_name}
								image={award.image}
								strategy={award.strategy}
								total_sales={award.total_sales}
								onEdit={() => {
									setOpenModal(true);
									setCurrentAward({
										id: award.id,
										position: index + 1,
										name: award.user_name,
										image: award.image,
										strategy: award.strategy,
										total_sales: award.total_sales,
										user_id: award.user_id,
									});
								}}
								onShow={() => {
									handleShowUser(award.user_id);
									setIsModalProfileOpen(true);
								}}
							/>
						))}
					</section>
				)}

				{isLoading && (
					<section className="mt-12 flex flex-col gap-4">
						{Array.from({ length: 5 }).map((_, index) => (
							<AwardCardSkeleton key={index} />
						))}
					</section>
				)}

				{(!award?.ranking.length || !isMonthEnded()) && !isLoading && (
					<div className="flex w-full flex-col items-center justify-center gap-12 md:py-36">
						<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
							<SearchIcon />
						</span>

						<div className="flex flex-col gap-2">
							<h3 className="w-[600px] text-center font-inter text-P2 font-medium leading-160 text-neutral-1100">
								Esse período ainda não tem um ranking definido!
							</h3>

							<p className="text-center font-inter text-P3 font-normal leading-160 text-neutral-500">
								{isMonthEnded()
									? "O ranking será exibido em breve."
									: "Após a conclusão do mês selecionado os ganhadores serão listados aqui."}
							</p>
						</div>
					</div>
				)}

				{openModal && (
					<Modal
						title={
							currentAward?.strategy
								? "Editar estratégia"
								: "Cadastrar estratégia"
						}
						closeModal={() => {
							setOpenModal(false);
							setCurrentAward(null);
						}}
						cancelAction={() => {
							setOpenModal(false);
							setCurrentAward(null);
						}}
						confirmAction={async () => {
							if (currentAward?.id) {
								await handleUpdateStrategy(strategy, currentAward.id);
							}
						}}
						confirmText={
							currentAward?.strategy ? "Salvar alterações" : "Cadastar"
						}
						className="!h-auto"
						isLoading={isLoading}
					>
						<div className="flex flex-col gap-8">
							{!currentAward?.strategy && (
								<div className="flex items-center gap-4 rounded-[8px] border border-solid border-orange-900 bg-orange-1000 p-4">
									<div>
										<svg
											width="40"
											height="40"
											viewBox="0 0 40 40"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<rect width="40" height="40" rx="20" fill="#FF9900" />
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M19.5 11C20.2933 11 20.9364 11.6318 20.9364 12.4111V22.4458C20.9364 23.2252 20.2933 23.857 19.5 23.857C18.7067 23.857 18.0636 23.2252 18.0636 22.4458V12.4111C18.0636 11.6318 18.7067 11 19.5 11ZM18 27.4632C18 26.6838 18.6431 26.0521 19.4364 26.0521H19.5636C20.3569 26.0521 21 26.6838 21 27.4632V27.5886C21 28.3669 20.3586 28.9982 19.5664 28.9998L19.4392 29C19.0578 29.0007 18.6917 28.8524 18.4217 28.5877C18.1517 28.3229 18 27.9636 18 27.5889V27.4632Z"
												fill="white"
											/>
										</svg>
									</div>

									<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
										Esse usuário não possui uma estratégia cadastrada!
									</p>
								</div>
							)}

							<div className="flex flex-1 justify-between gap-5 rounded-xl bg-neutral-1100 bg-opacity-[0.03] p-4">
								<div className="flex items-center gap-2">
									<div
										className={`flex items-center gap-2 text-[${
											positionTrophyColor[
												currentAward?.position as keyof typeof positionTrophyColor
											]
										}]`}
									>
										<TrophyIcon width={24} height={24} />

										<span className="w-7 font-inter text-P3 font-semibold leading-140 text-neutral-1100">
											{currentAward?.position}
										</span>
									</div>

									<div className="flex items-center gap-4">
										<Avatar className="size-8 border-none border-neutral-0">
											<AvatarImage src={currentAward?.image} />
											<AvatarFallback className="!text-P7 text-neutral-1100">
												{authorFallback(currentAward?.name)}
											</AvatarFallback>
										</Avatar>

										<p className="truncate font-inter text-P5 font-normal leading-160 text-neutral-1100">
											{currentAward?.name}
										</p>
									</div>
								</div>

								<div className="flex items-center gap-2">
									<span className="font-inter text-P6 font-normal leading-160 text-neutral-400">
										Vendas
									</span>

									<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
										{currentAward?.total_sales}
									</p>
								</div>
							</div>

							<TextareaInput
								value={strategy}
								setValue={(e) => {
									setStrategy(e);
								}}
								placeholder="Estratégia utilizada"
								showCounter
								showButtons={false}
								maxLength={500}
								heightIsFixed
								height="150px"
							/>
						</div>
					</Modal>
				)}

				{isModalProfileOpen && (
					<AdminUsersModalProfile
						closeModal={() => setIsModalProfileOpen(false)}
						user={currentUserData}
					/>
				)}

				{successModal && (
					<AlertModal
						title={
							currentAward?.strategy
								? "Estratégia atualizada com sucesso!"
								: "Estratégia cadastrada com sucesso!"
						}
						icon="done.svg"
						confirmText="Concluir"
						hasCancel={false}
						confirmAction={() => {
							setSuccessModal(false);
						}}
						closeModal={() => {
							setSuccessModal(false);
						}}
					/>
				)}
			</div>
		</>
	);
}

interface AwardCardProps {
	position: number;
	name: string;
	image: string;
	strategy: string | null;
	total_sales: number;
	onEdit: () => void;
	onShow: () => void;
}

function AwardCard({
	position,
	name,
	image,
	strategy,
	total_sales,
	onEdit,
	onShow,
}: AwardCardProps) {
	const optionsIcons: { [key: string]: JSX.Element } = {
		smileMouthOpen: <SmileMouthOpenIcon />,
		pen: <PenIcon />,
	};

	const getOptions = () => {
		return [
			{
				title: "Editar estratégia",
				icon: "pen",
			},
			{
				title: "Dados do usuário",
				icon: "smileMouthOpen",
			},
		];
	};

	return (
		<div className="flex h-[80px] w-full flex-1 items-center justify-between gap-5 rounded-[12px] border border-solid border-neutral-100 bg-neutral-1100 bg-opacity-[0.03] px-6 py-4">
			<div className="flex flex-1 justify-between gap-5">
				<div className="flex items-center gap-10">
					<div
						className={`flex items-center gap-2
							text-[${positionTrophyColor[position as keyof typeof positionTrophyColor]}]
						`}
					>
						<TrophyIcon width={24} height={24} />

						<span className="w-7 font-inter text-P3 font-semibold leading-140 text-neutral-1100">
							{position}
						</span>
					</div>

					<div className="flex items-center gap-4">
						<Avatar className="size-8 border-none border-neutral-0">
							<AvatarImage src={image} />
							<AvatarFallback className="!text-P7 text-neutral-1100">
								{authorFallback(name)}
							</AvatarFallback>
						</Avatar>

						<p className="truncate font-inter text-P5 font-normal leading-160 text-neutral-1100">
							{name}
						</p>
					</div>
				</div>

				<div className="flex items-center gap-2">
					<span className="font-inter text-P6 font-normal leading-160 text-neutral-400">
						Vendas
					</span>

					<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
						{total_sales}
					</p>
				</div>
			</div>

			<div className="flex items-center gap-5">
				{!strategy && (
					<Tag
						size="small"
						colors="minimalist-orange"
						className="rounded-[4px] px-[6px] py-1 font-inter text-P7 font-normal leading-160"
					>
						Estratégia não cadastrada
					</Tag>
				)}

				<span className="relative flex size-8 cursor-pointer items-center justify-center p-1 !text-neutral-600 hover:rounded-[8px] hover:bg-neutral-200">
					<DropdownMenu>
						<DropdownMenuTrigger className="relative focus-visible:outline-none">
							<MoreAltIcon />
						</DropdownMenuTrigger>
						<DropdownMenuContent className="absolute -right-4 !w-[200px]">
							{getOptions().map((option, index) => (
								<DropdownMenuItem
									key={index + "option"}
									onClick={() => {
										if (option.title === "Editar estratégia") {
											onEdit();
										} else {
											onShow();
										}
									}}
								>
									{optionsIcons[option.icon]}

									<p>{option.title}</p>
								</DropdownMenuItem>
							))}
						</DropdownMenuContent>
					</DropdownMenu>
				</span>
			</div>
		</div>
	);
}

function AwardCardSkeleton() {
	return (
		<div className="flex h-[80px] w-full flex-1 animate-pulse items-center justify-between gap-5 rounded-[12px] border border-solid border-neutral-100 bg-neutral-1100 bg-opacity-[0.03] px-6 py-4">
			<div className="flex flex-1 justify-between gap-5">
				<div className="flex items-center gap-10">
					<div className="flex items-center gap-2">
						<div className="h-6 w-6 bg-neutral-200"></div>
						<div className="h-4 w-6 rounded bg-neutral-200"></div>
					</div>

					<div className="flex items-center gap-4">
						<div className="h-8 w-8 rounded-full bg-neutral-200"></div>
						<div className="h-5 w-24 rounded bg-neutral-200"></div>
					</div>
				</div>

				<div className="flex items-center gap-2">
					<div className="h-6 w-20 rounded bg-neutral-200"></div>
				</div>
			</div>

			<div className="flex items-center gap-5">
				<div className="h-8 w-8 rounded bg-neutral-200"></div>
			</div>
		</div>
	);
}
