import { AxiosError } from "axios";

import { api } from "@/lib/axios";

import { DeleteHelpTopicError, DeleteHelpTopicResponse } from "./help-topics";

export async function deleteHelpTopic(
	hash: string,
): Promise<
	| { status: "success"; data: DeleteHelpTopicResponse }
	| { status: "error"; errors: DeleteHelpTopicError }
> {
	try {
		const response = await api.delete<DeleteHelpTopicResponse>(
			`/help-topic/delete/${hash}`,
		);

		return {
			status: "success",
			data: response.data,
		};
	} catch (error) {
		const axiosError = error as AxiosError<DeleteHelpTopicError>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data,
			};
		}

		return {
			status: "error",
			errors: {
				message: "An unknown error occurred. Please try again later.",
				errors: {
					unknown: ["An unknown error occurred. Please try again later."],
				},
			},
		};
	}
}
