import { ReactNode, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import BoxIcon from "@/assets/box.svg?react";
import HomeIcon from "@/assets/home.svg?react";
import InfoIcon from "@/assets/info.svg?react";
import InsightsIcon from "@/assets/insights.svg?react";
import InstagramIcon from "@/assets/instagram.svg?react";
import Instagram2Icon from "@/assets/instagram-2.svg?react";
import PlayButtonOIcon from "@/assets/play-button-o.svg?react";
import SettingsIcon from "@/assets/settings.svg?react";
import TrophyIcon from "@/assets/trophy.svg?react";
import { useAuthStore } from "@/store/auth";
import { useNavigationStore } from "@/store/navigation-store";

import { Tag } from "./tag";

export function NavBar() {
	const { menuIsOpen, setMenuIsOpen } = useNavigationStore();
	const { profile } = useAuthStore();
	const navRef = useRef<HTMLDivElement>(null);

	const toggleMenu = () => {
		if (window.innerWidth < 1280) {
			setMenuIsOpen(!menuIsOpen);
		}
	};

	useEffect(() => {
		const navElement = navRef.current;
		if (!navElement) return;

		const handleMouseEnter = () => {
			navElement.classList.add("scroll-active");
		};

		const handleMouseLeave = () => {
			navElement.classList.remove("scroll-active");
		};

		if (navElement) {
			navElement.addEventListener("mouseenter", handleMouseEnter);
			navElement.addEventListener("mouseleave", handleMouseLeave);
		}

		return () => {
			if (navElement) {
				navElement.removeEventListener("mouseenter", handleMouseEnter);
				navElement.removeEventListener("mouseleave", handleMouseLeave);
			}
		};
	}, []);

	return (
		<div
			ref={navRef}
			className={`nav-bar flex h-screen flex-col gap-16 overflow-y-hidden border-r border-neutral-100 bg-neutral-0 px-6 py-8`}
			style={menuIsOpen ? { height: "calc(100vh - 98px)" } : {}}
		>
			<Link to="/" className="hidden xl:flex">
				<img src="/logo.svg" alt="Logo Suportefy" width={110} height={32} />
			</Link>

			<nav className="flex flex-1 flex-col justify-between xl:gap-14">
				<ul className="flex flex-col gap-6">
					<div className="flex flex-col gap-4">
						<MenuItem name="Home" to="/" toggleMenu={toggleMenu}>
							<HomeIcon />
						</MenuItem>
						<MenuItem name="Produtos" to="/products" toggleMenu={toggleMenu}>
							<BoxIcon />
						</MenuItem>
						<MenuItem name="Academy" to="/academy" toggleMenu={toggleMenu}>
							<PlayButtonOIcon />
						</MenuItem>
						<MenuItem name="Premiações" to="/awards" toggleMenu={toggleMenu}>
							<TrophyIcon />
						</MenuItem>
						<MenuItem name="Dashboard" to="/dashboard" toggleMenu={toggleMenu}>
							<InsightsIcon />
						</MenuItem>
					</div>
					<hr className="h-px border-t-neutral-100" />
					<div className="flex flex-col gap-4">
						{profile?.isAdmin && (
							<MenuItem
								name="Configurações"
								to="/admin"
								toggleMenu={toggleMenu}
							>
								<SettingsIcon />
							</MenuItem>
						)}
						<MenuItem name="Ajuda" to="/help" toggleMenu={toggleMenu}>
							<InfoIcon />
						</MenuItem>
					</div>
				</ul>

				<div className="relative flex gap-[10px] rounded-[8px] bg-neutral-100 bg-opacity-60 px-3 py-4">
					<InstagramIcon />
					<div className="flex flex-col gap-1">
						<p className="font-inter text-P5 font-medium leading-160 text-white">
							Siga nosso instagram
						</p>
						<a
							href="https://www.instagram.com/suportefy"
							target="_blank"
							rel="noopener noreferrer"
							className="cursor-pointer font-inter text-P7 font-medium leading-160 text-neutral-600 transition-colors hover:text-neutral-800 hover:underline hover:underline-offset-8"
						>
							Clique aqui e acesse!
						</a>
					</div>
					<Instagram2Icon className="absolute left-0 top-0" />
				</div>
			</nav>
		</div>
	);
}

function MenuItem({
	name,
	to,
	children,
	disabled = false,
	toggleMenu,
}: {
	name: string;
	to: string;
	children: ReactNode;
	disabled?: boolean;
	toggleMenu: () => void;
}) {
	const location = useLocation();
	const TO_WITHOUT_SLASH = to.replace("/", "");

	function isActive() {
		const fullPath = location.pathname;
		const path = fullPath?.split("/")[1];
		return path === TO_WITHOUT_SLASH;
	}

	const handleClick = () => {
		if (!disabled) {
			toggleMenu();
		}
	};

	return (
		<li className="h-[48px] w-[208px]">
			<Link
				to={!disabled ? to : location.pathname}
				className={`flex items-center justify-between ${
					disabled && "cursor-not-allowed"
				}`}
				onClick={handleClick}
			>
				<div
					className={`flex flex-1 items-center gap-3 rounded-[8px] p-3 text-neutral-400 transition-all hover:text-white ${
						isActive() && "bg-neutral-100	!text-white"
					} ${disabled && "cursor-not-allowed !text-neutral-200"}`}
				>
					{children}
					<p className="font-inter text-P5 font-medium leading-160">{name}</p>
				</div>

				{disabled && (
					<Tag
						size="small"
						colors="minimalist-grey"
						className="px-[6px] py-1 text-[10px]"
					>
						Em breve
					</Tag>
				)}
			</Link>
		</li>
	);
}
