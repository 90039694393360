import { useState } from "react";

import PenIcon from "@/assets/pen.svg?react";
import TrashIcon from "@/assets/trash.svg?react";

interface AdminTopicCardProps {
	name: string;
	questions: number;
	onEdit: () => void;
	onRemove: () => void;
}

export function AdminTopicCard({
	name,
	questions,
	onEdit,
	onRemove,
}: AdminTopicCardProps) {
	const [isPenHovered, setIsPenHovered] = useState(false);
	const [isTrashHovered, setIsTrashHovered] = useState(false);

	return (
		<div className="flex items-center justify-between rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-4">
			<div className="flex flex-col gap-0.5">
				<p className="line-clamp-1 font-inter text-P5 font-medium leading-160 text-neutral-1100">
					{name}
				</p>

				<span className="font-inter text-P6 font-normal leading-160 text-neutral-600">
					{questions} dúvidas adicionadas
				</span>
			</div>

			<div className="flex items-center gap-1">
				<button
					className={`flex items-center justify-center rounded-[6px] p-3
						${isPenHovered ? "bg-neutral-200 text-neutral-1100" : "text-neutral-600"}
						`}
					onClick={onEdit}
					onMouseEnter={() => setIsPenHovered(true)}
					onMouseLeave={() => setIsPenHovered(false)}
				>
					<PenIcon />
				</button>

				<button
					className={`flex items-center justify-center rounded-[6px] p-3
							disabled:cursor-not-allowed disabled:opacity-50
						${isTrashHovered ? "bg-neutral-200 text-neutral-1100" : "text-neutral-600"}
						`}
					onClick={onRemove}
					onMouseEnter={() => setIsTrashHovered(true)}
					onMouseLeave={() => setIsTrashHovered(false)}
					disabled={questions > 0}
				>
					<TrashIcon />
				</button>
			</div>
		</div>
	);
}
