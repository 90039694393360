import { AxiosError } from "axios";

import { api } from "@/lib/axios";

type Platform = {
	platform_id: string;
	link: string;
	external_id: string;
};

export interface createAdminProductsParams {
	name: string;
	image: File | string;
	type_id: string;
	niche_id: string;
	telegram_link: string;
	status_sale_link: boolean;
	sale_link: string;
	platforms: Platform[];
}

export async function createAdminProducts(data: FormData): Promise<
	| {
			id: string;
			status: "success";
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const response = await api.post("/product/store", data);

		return {
			id: response.data.data.id,
			status: "success",
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
