interface HelpHeaderProps {
	profile: { nickname?: string; name?: string } | null;
}

export function HelpHeader({ profile }: HelpHeaderProps) {
	return (
		<section className="flex flex-col gap-2">
			<p className="font-inter text-P2 font-medium leading-140 text-neutral-600">
				Olá, {profile?.nickname || profile?.name}!
			</p>

			<h2 className="font-poppins text-H6 font-medium leading-140 text-neutral-1100">
				Como podemos te ajudar?
			</h2>
		</section>
	);
}
