import ChevronRightIcon from "@/assets/chevron-right.svg?react";
import FileDocumentIcon from "@/assets/file-document.svg?react";

interface ContentCardProps {
	title: string;
	onNavigate?: () => void;
	hasIcon?: boolean;
}

export function ContentCardNavigation({
	title,
	onNavigate,
	hasIcon = true,
}: ContentCardProps) {
	return (
		<div
			className="flex h-full max-h-[62px] cursor-pointer items-center justify-between rounded-xl border border-neutral-100 p-4 transition-all duration-300 hover:bg-neutral-100"
			onClick={onNavigate}
		>
			<div className="flex items-center gap-6 overflow-hidden">
				{hasIcon && <FileDocumentIcon className="flex-shrink-0" />}
				<p className="overflow-hidden text-ellipsis whitespace-nowrap font-inter text-P4 font-medium leading-160 text-neutral-1100">
					{title}
				</p>
			</div>
			<ChevronRightIcon className="flex-shrink-0 text-neutral-200" />
		</div>
	);
}
