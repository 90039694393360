import { useEffect, useRef } from "react";

import ArrowLeftIcon from "@/assets/arrow-left.svg?react";
import { Card } from "@/components/card";
import { ContentCardNavigation } from "@/components/content-card-navigation";

interface Question {
	id: string;
	question: string;
	answer: string;
}

interface QuestionDetailProps {
	currentQuestion: Question | null;
	allQuestions: Question[];
	setCurrentQuestion: (question: Question | null) => void;
}

export function QuestionDetail({
	currentQuestion,
	allQuestions,
	setCurrentQuestion,
}: QuestionDetailProps) {
	const answerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (answerRef.current) {
			const links = answerRef.current.getElementsByTagName("a");
			for (let i = 0; i < links.length; i++) {
				links[i].setAttribute("target", "_blank");
				links[i].setAttribute("rel", "noopener noreferrer");
			}
		}
	}, [currentQuestion]);

	if (!currentQuestion) {
		return <div>Question not found</div>;
	}

	const relatedQuestions = allQuestions
		.filter((q) => q.id !== currentQuestion.id)
		.slice(0, 3);

	return (
		<div className="flex flex-col justify-between gap-24 md:flex-row">
			<article className="flex w-full flex-col gap-8">
				<button
					onClick={() => {
						setCurrentQuestion(null);
					}}
					className="flex cursor-pointer gap-1 font-inter text-P5 font-medium leading-160 text-neutral-600 transition-all duration-300 hover:text-neutral-1100 hover:underline hover:underline-offset-8"
				>
					<ArrowLeftIcon width={20} height={20} />
					<p>Voltar</p>
				</button>

				<div className="flex flex-col gap-6">
					<h3 className="font-inter text-P1 font-medium leading-140 text-neutral-1100">
						{currentQuestion.question}
					</h3>

					<div
						ref={answerRef}
						className="answer-content font-inter text-P5 font-normal leading-160 text-neutral-500"
						dangerouslySetInnerHTML={{ __html: currentQuestion.answer }}
					/>
				</div>
			</article>

			<aside className="sticky top-32 flex h-fit min-w-[352px] flex-col gap-8 rounded-xl border border-solid border-neutral-100 p-6">
				<div className="flex flex-col gap-6">
					<h4 className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
						Dúvidas relacionadas
					</h4>

					<div className="flex flex-col gap-4">
						{relatedQuestions.map((question) => (
							<ContentCardNavigation
								key={question.id}
								title={question.question}
								hasIcon={false}
								onNavigate={() => {
									setCurrentQuestion(question);
								}}
							/>
						))}
					</div>
				</div>

				<div className="flex flex-col gap-4">
					<Card
						title="Suporte"
						description="Entre em contato com nosso time para mais informações."
						to="products"
						linkName="Acessar"
						external
					/>
					<Card
						title="Telegram"
						description="Fique por dentro das novidades e avisos em nosso canal."
						to="academy"
						linkName="Acessar"
						external
					/>
				</div>
			</aside>
		</div>
	);
}
