import { AxiosError } from "axios";

import { api } from "@/lib/axios";

import { HelpTopicListResponse } from "./help-topics";

export async function fetchHelpTopicsList(): Promise<
	| {
			status: "success";
			data: HelpTopicListResponse;
	  }
	| { status: "error"; error: string }
> {
	try {
		const { data } = await api.get<HelpTopicListResponse>("/help-topic/list");

		return {
			status: "success",
			data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError;

		if (axiosError.response) {
			return {
				status: "error",
				error: `Error ${axiosError.response.status}: ${axiosError.response.statusText}`,
			};
		}

		return {
			status: "error",
			error: "An unknown error occurred. Please try again later.",
		};
	}
}
