import { ReactNode, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import BoxIcon from "@/assets/box.svg?react";
import ChevronRightIcon from "@/assets/chevron-right.svg?react";
import InfoIcon from "@/assets/info.svg?react";
import InsightsIcon from "@/assets/insights.svg?react";
import PlayButtonOIcon from "@/assets/play-button-o.svg?react";
import TrophyIcon from "@/assets/trophy.svg?react";
import UsersIcon from "@/assets/users.svg?react";
import { Tag } from "@/components/tag";
import { useAuthStore } from "@/store/auth";

export function Admin() {
	const navigate = useNavigate();
	const { profile } = useAuthStore();

	useEffect(() => {
		if (!profile?.isAdmin) {
			navigate("/");
		}
	}, [profile]);

	return (
		<div className="relative flex gap-0 md:gap-16">
			<section className="sticky top-32 hidden h-fit min-w-72 flex-col gap-8 rounded-xl bg-neutral-100 p-6 md:flex">
				<nav className="flex flex-col gap-8">
					<div className="flex flex-col gap-6">
						<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
							Visão geral
						</p>
						<div className="flex flex-col gap-2">
							<ListItem to="/admin" name="Painel">
								<InsightsIcon width={20} height={20} />
							</ListItem>

							<ListItem to="/admin/users" name="Usuários">
								<UsersIcon width={20} height={20} />
							</ListItem>
						</div>
					</div>

					<hr className="h-px border-t-neutral-200" />

					<div className="flex flex-col gap-6">
						<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
							Categorias
						</p>
						<div className="flex flex-col gap-2">
							<ListItem to="/admin/academy" name="Academy">
								<PlayButtonOIcon width={20} height={20} />
							</ListItem>

							<ListItem to="/admin/products" name="Produtos">
								<BoxIcon width={20} height={20} />
							</ListItem>

							<ListItem to="/admin/help" name="Ajuda">
								<InfoIcon className="-ml-0.5" />
							</ListItem>
						</div>
					</div>

					<hr className="h-px border-t-neutral-200" />

					<div className="flex flex-col gap-6">
						<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
							Personalizar
						</p>
						<div className="flex flex-col gap-2">
							<ListItem to="/admin/awards" name="Premiações">
								<TrophyIcon width={20} height={20} />
							</ListItem>
						</div>
					</div>
				</nav>
			</section>

			<section className="w-full pt-4 md:pl-8">
				<Outlet />
			</section>
		</div>
	);
}

function ListItem({
	name,
	to,
	children,
	disabled = false,
}: {
	name: string;
	to: string;
	children: ReactNode;
	disabled?: boolean;
}) {
	const location = useLocation();
	const TO_WITHOUT_SLASH = to.replace("/", "");

	function isActive() {
		const fullPath = location.pathname;
		const path = fullPath?.split("/");

		const newPath = `${path[1]}${path[2] ? "/" + path[2] : ""}`;

		return newPath === TO_WITHOUT_SLASH;
	}

	return (
		<Link
			to={!disabled ? to : "/admin"}
			className={`flex items-center justify-between rounded-[8px] px-2 py-3 text-neutral-400 transition-all duration-300 hover:bg-neutral-200 hover:text-white ${
				isActive() && "bg-neutral-200	!text-white"
			} ${disabled && "cursor-not-allowed !bg-neutral-100 !text-neutral-200"}
			`}
		>
			<div className={`flex flex-1 items-center gap-3`}>
				{children}

				<p className="font-inter text-P5 font-medium leading-160">{name}</p>
			</div>

			{!disabled && <ChevronRightIcon width={20} height={20} />}

			{disabled && (
				<Tag
					size="small"
					colors="minimalist-grey"
					className="px-[6px] py-1 text-[10px]"
				>
					Em breve
				</Tag>
			)}
		</Link>
	);
}
