import type { HelpQuestion } from "@/api/admin/help/help-topics";
import SearchIcon from "@/assets/search.svg?react";
import { ContentCardNavigation } from "@/components/content-card-navigation";

interface QuestionListProps {
	questions: HelpQuestion[];
	setCurrentQuestion: (question: HelpQuestion | null) => void;
}

export function QuestionList({
	questions,
	setCurrentQuestion,
}: QuestionListProps) {
	if (!questions || questions.length === 0) {
		return (
			<div className="flex h-full w-full flex-col items-center justify-center gap-6 py-36">
				<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
					<SearchIcon />
				</span>
				<div className="flex flex-col items-center gap-2">
					<h3 className="text-center font-inter text-P2 font-medium leading-140 text-neutral-1100">
						Não encontramos nenhum resultado para sua busca!
					</h3>

					<p className="text-center font-inter text-P3 font-normal leading-160 text-neutral-500">
						Pesquise por palavras-chave ou termos relacionados ao seu assunto de
						interesse.
					</p>
				</div>
			</div>
		);
	}

	return (
		<div className="w-full">
			<div className="flex flex-col gap-6">
				{questions.map((question) => (
					<ContentCardNavigation
						key={question.id}
						title={question.question}
						hasIcon={false}
						onNavigate={() => {
							setCurrentQuestion(question);
						}}
					/>
				))}
			</div>
		</div>
	);
}
