import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { deleteHelpTopic } from "@/api/admin/help/delete-help-topic";
import { fetchHelpTopics } from "@/api/admin/help/fetch-help-topics";
import type { HelpTopic } from "@/api/admin/help/help-topics";
import { saveHelpTopic } from "@/api/admin/help/save-help-topic";
import { updateHelpTopic } from "@/api/admin/help/update-help-topic";
import CloseIcon from "@/assets/close.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import { AdminTopicCard } from "@/components/admin-topic-card";
import { AlertModal } from "@/components/alert-modal";
import { DeleteModal } from "@/components/delete-modal";
import { Modal } from "@/components/modal";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form } from "@/components/ui/form";
import { Input } from "@/components/ui/input";

interface AdminHelpEditTopicsProps {
	title: string;
	closeModal: () => void;
}

export function AdminHelpEditTopics({
	title,
	closeModal,
}: AdminHelpEditTopicsProps) {
	const [showTopicModal, setShowTopicModal] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [filteredTopics, setFilteredTopics] = useState<HelpTopic[]>([]);
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedTopic, setSelectedTopic] = useState<HelpTopic | null>(null);
	const [successModal, setSuccessModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [successDeleteModal, setSuccessDeleteModal] = useState(false);
	const [topicToDelete, setTopicToDelete] = useState<HelpTopic | null>(null);

	useEffect(() => {
		fetchTopics();
	}, []);

	async function fetchTopics(search = "") {
		setIsLoading(true);
		try {
			const response = await fetchHelpTopics({
				title: search || undefined,
			});
			if (response.status === "success") {
				setFilteredTopics(response.data.data);
			} else {
				console.error("Error fetching topics:", response.errors);
			}
		} catch (error) {
			console.error("Error fetching topics:", error);
		}
		setIsLoading(false);
	}

	async function handleSearch(searchTerm: string) {
		setSearchTerm(searchTerm);
		await fetchTopics(searchTerm);
	}

	const filter = z.object({
		name: z.string().min(3, {
			message: "O nome do assunto é obrigatório.",
		}),
	});

	type Filter = z.infer<typeof filter>;

	const form = useForm<Filter>({
		resolver: zodResolver(filter),
	});

	async function handleSubmit(data: Filter) {
		setIsLoading(true);

		try {
			let response;
			if (isEditing && selectedTopic) {
				response = await updateHelpTopic(selectedTopic.id, {
					name: data.name,
				});
				setSuccessModal(true);
			} else {
				response = await saveHelpTopic({
					name: data.name,
				});
				setSuccessModal(true);
			}

			if (response.status === "success") {
				//onSuccess();
				//setShowTopicModal(false);
				fetchTopics(searchTerm);
			} else {
				console.error("Error saving/updating topic:", response.errors);
			}
		} catch (error) {
			console.error("Error saving/updating topic:", error);
		}

		setIsLoading(false);
	}

	async function confirmDelete() {
		setIsLoading(true);
		try {
			const response = await deleteHelpTopic(topicToDelete!.id);
			if (response.status === "success") {
				setSuccessDeleteModal(true);
				fetchTopics(searchTerm);
			} else {
				console.error("Error deleting topic:", response.errors);
			}
		} catch (error) {
			console.error("Error deleting topic:", error);
		}
		setIsLoading(false);
		setDeleteModal(false);
	}

	async function handleDelete(topic: HelpTopic) {
		setTopicToDelete(topic);
		setDeleteModal(true);
	}

	return (
		<Modal
			title={showTopicModal ? "Novo assunto" : title}
			closeModal={closeModal}
			cancelAction={closeModal}
			confirmText={showTopicModal ? "Salvar" : "Adicionar assunto"}
			isLoading={isLoading}
			className={showTopicModal ? "!h-auto" : ""}
			confirmAction={() => {
				if (showTopicModal) {
					form.handleSubmit(handleSubmit)();
				} else {
					setShowTopicModal(true);
				}
			}}
			backAction={() => {
				if (showTopicModal) {
					form.clearErrors();
					form.reset();
					setSelectedTopic(null);
					setShowTopicModal(false);
					setIsEditing(false);
				}
			}}
			showBackButton={showTopicModal}
		>
			{!showTopicModal && (
				<div className="flex flex-col gap-8">
					<div
						className={`relative ${
							filteredTopics.length > 0
								? "text-neutral-600"
								: "text-neutral-200"
						} focus-within:text-neutral-1100`}
					>
						<Input
							placeholder="Pesquise pelo nome do assunto"
							className={`h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:!text-P5  ${
								filteredTopics.length > 0
									? "placeholder:text-neutral-600"
									: "placeholder:text-neutral-200"
							}`}
							onChange={(e) => handleSearch(e.target.value)}
							value={searchTerm}
						/>
						<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

						{searchTerm && (
							<button
								className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-200 text-neutral-1100"
								onClick={() => handleSearch("")}
							>
								<CloseIcon />
							</button>
						)}
					</div>

					<div className="flex flex-col gap-4">
						{filteredTopics.map((topic) => (
							<AdminTopicCard
								key={topic.id}
								name={topic.name}
								questions={topic.questions.length}
								onEdit={() => {
									setIsEditing(true);
									setShowTopicModal(true);
									setSelectedTopic(topic);

									form.setValue("name", topic.name);
								}}
								onRemove={() => handleDelete(topic)}
							/>
						))}

						{!filteredTopics.length && !isLoading && (
							<div className="flex flex-col items-center justify-center gap-6 py-14">
								<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
									<SearchIcon />
								</span>
								<h3 className="w-[240px] text-center font-inter font-medium leading-160 text-neutral-1100">
									{searchTerm
										? "Nenhum resultado encontrado"
										: "Nenhum assunto encontrado"}
								</h3>
							</div>
						)}
					</div>
				</div>
			)}

			{showTopicModal && (
				<Form {...form}>
					<div className="flex flex-col gap-8">
						<div className="flex w-full flex-col items-center gap-6">
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="name"
									type="text"
									label="Nome do assunto"
									hasError={!!form.formState.errors.name}
									{...form.register("name")}
								/>

								{form.formState.errors.name && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.name.message}
									</p>
								)}
							</div>
						</div>
					</div>
				</Form>
			)}
			{successModal && (
				<AlertModal
					title="Alterações salvas com sucesso!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessModal(false);
						setShowTopicModal(false);
						form.reset();
						setSelectedTopic(null);
						fetchTopics(searchTerm);
					}}
					closeModal={() => {
						setSuccessModal(false);
						setShowTopicModal(false);
						form.reset();
						setSelectedTopic(null);
						fetchTopics(searchTerm);
					}}
				/>
			)}

			{deleteModal && (
				<DeleteModal
					title="Deseja realmente excluir este assunto?"
					description="O mesmo será desvinculado de todas as perguntas relacionadas."
					icon="warning.svg"
					confirmText="Excluir assunto"
					isLoading={isLoading}
					cancelAction={() => setDeleteModal(false)}
					confirmAction={confirmDelete}
					closeModal={() => setDeleteModal(false)}
				/>
			)}

			{successDeleteModal && (
				<AlertModal
					title="Assunto excluído!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessDeleteModal(false);
						fetchTopics(searchTerm);
					}}
					closeModal={() => {
						setSuccessDeleteModal(false);
						fetchTopics(searchTerm);
					}}
				/>
			)}
		</Modal>
	);
}
