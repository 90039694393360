import { AxiosError } from "axios";

import { api } from "@/lib/axios";

import {
	SaveHelpQuestionError,
	SaveHelpQuestionRequest,
	SaveHelpQuestionResponse,
} from "./help-topics";

export async function saveHelpQuestion(
	data: SaveHelpQuestionRequest,
): Promise<
	| { status: "success"; data: SaveHelpQuestionResponse }
	| { status: "error"; errors: SaveHelpQuestionError }
> {
	try {
		const response = await api.post<SaveHelpQuestionResponse>(
			"/help-question/store",
			data,
		);

		return {
			status: "success",
			data: response.data,
		};
	} catch (error) {
		const axiosError = error as AxiosError<SaveHelpQuestionError>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data,
			};
		}

		return {
			status: "error",
			errors: {
				message: "An unknown error occurred. Please try again later.",
				errors: {
					unknown: ["An unknown error occurred. Please try again later."],
				},
			},
		};
	}
}
