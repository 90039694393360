import { useState } from "react";

import Search2Icon from "@/assets/search-2.svg?react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

interface HelpSearchProps {
	onSearch: (query: string) => void;
}

export function HelpSearch({ onSearch }: HelpSearchProps) {
	const [searchQuery, setSearchQuery] = useState("");

	const handleSearch = () => {
		onSearch(searchQuery);
	};

	return (
		<div className="flex flex-col gap-8">
			<h3 className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
				Confira também nossa sessão de dúvidas frequentes
			</h3>

			<div
				className={`relative text-neutral-600 focus-within:text-neutral-1100`}
			>
				<Input
					placeholder="Insira sua dúvida..."
					className="h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:!text-P5 placeholder:text-neutral-600"
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
					onKeyPress={(e) => e.key === "Enter" && handleSearch()}
				/>
				<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

				<Button
					variant="primary"
					size="default"
					className="absolute right-3.5 top-1/2 flex h-8 w-fit -translate-y-1/2 items-center justify-center rounded px-4 !text-P7 font-semibold leading-160"
					onClick={handleSearch}
					type="button"
				>
					Buscar
				</Button>
			</div>
		</div>
	);
}
