import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { deleteHelpQuestion } from "@/api/admin/help/delete-help-question";
import { fetchHelpTopics } from "@/api/admin/help/fetch-help-topics";
import { fetchHelpTopicsList } from "@/api/admin/help/fetch-help-topics-list";
import type {
	HelpTopic,
	HelpTopicListItem,
} from "@/api/admin/help/help-topics";
import CloseIcon from "@/assets/close.svg?react";
import MathPlus from "@/assets/math-plus.svg?react";
import OptionsIcon from "@/assets/options.svg?react";
import PenIcon from "@/assets/pen.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import { AdminQuestionCard } from "@/components/admin-question-card";
import { AlertModal } from "@/components/alert-modal";
import { DeleteModal } from "@/components/delete-modal";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { useNavigationStore } from "@/store/navigation-store";

import { AdminHelpAddQuestion } from "./add-question";
import { AdminHelpEditTopics } from "./edit-topics";

export function AdminHelp() {
	const [filterIsOpen, setFilterIsOpen] = useState(false);
	const [mentorModalIsOpen, setMentorModalIsOpen] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [topics, setTopics] = useState<HelpTopicListItem[]>([]);
	const [help, setHelp] = useState<HelpTopic[]>([]);
	const [currentQuestion, setCurrentQuestion] = useState<{
		id: string;
		question: string;
		answer: string;
		topic_id: string;
	} | null>(null);
	const [deleteModal, setDeleteModal] = useState(false);
	const [questionToDelete, setQuestionToDelete] = useState<{
		id: string;
		question: string;
	} | null>(null);
	const [successDeleteModal, setSuccessDeleteModal] = useState(false);

	async function handleDeleteQuestion(
		questionId: string,
		questionText: string,
	) {
		setQuestionToDelete({ id: questionId, question: questionText });
		setDeleteModal(true);
	}

	async function confirmDelete() {
		if (questionToDelete) {
			setIsLoading(true);
			try {
				const response = await deleteHelpQuestion(questionToDelete.id);
				if (response.status === "success") {
					setSuccessDeleteModal(true);
					fetchHelp();
				} else {
					console.error("Error deleting question:", response.errors);
				}
			} catch (error) {
				console.error("Error deleting question:", error);
			}
			setIsLoading(false);
			setDeleteModal(false);
		}
	}

	const filter = z.object({
		name: z.string().optional(),
		topic: z.string().optional(),
	});

	type Filter = z.infer<typeof filter>;

	const form = useForm<Filter>();

	async function handleFilterSubmit(data: Filter) {
		setIsLoading(true);

		const response = await fetchHelpTopics({
			title: data.name || undefined,
			matter: data.topic || undefined,
		});

		if (response.status === "success") {
			setHelp(response.data.data);
		}

		setIsLoading(false);
		setFilterIsOpen(false);
	}

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	async function fetchTopics() {
		setIsLoading(true);

		const response = await fetchHelpTopicsList();

		if (response.status === "success") {
			setTopics(response.data.data);
		}

		setIsLoading(false);
	}

	async function fetchHelp(search = "") {
		setIsLoading(true);
		try {
			const response = await fetchHelpTopics({
				title: search || undefined,
			});
			if (response.status === "success") {
				setHelp(response.data.data);
			} else {
				console.error("Error fetching topics:", response.errors);
			}
		} catch (error) {
			console.error("Error fetching topics:", error);
		}
		setIsLoading(false);
	}

	useEffect(() => {
		const paths = [
			{
				name: "Configurações",
				path: "/admin",
			},
			{
				name: "Ajuda",
				path: "/admin/help",
			},
		];

		setPaths(paths);
		fetchTopics();
		fetchHelp();
	}, []);

	return (
		<>
			<Helmet title="Ajuda" />

			<div className="flex h-full flex-col">
				<header className="flex items-center justify-between">
					<h2 className="font-inter text-P2 font-medium leading-140 text-neutral-1100">
						Todas as dúvidas
					</h2>

					<div className="flex items-center justify-center gap-4">
						<button
							className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 px-6 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400"
							disabled={isLoading}
							onClick={() => setFilterIsOpen(!filterIsOpen)}
						>
							<OptionsIcon />

							<p className="font-inter text-P6 font-semibold leading-160">
								Filtro
							</p>
						</button>

						<button
							className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 px-6 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400"
							disabled={isLoading}
							onClick={() => setMentorModalIsOpen(!mentorModalIsOpen)}
						>
							<PenIcon />

							<p className="font-inter text-P6 font-semibold leading-160">
								Editar assuntos
							</p>
						</button>

						<button
							className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-primary-600 px-6 text-neutral-1100 transition-all duration-300 hover:bg-primary-700"
							onClick={() => setModalIsOpen(true)}
						>
							<MathPlus />

							<p className="font-inter text-P6 font-semibold leading-160">
								Adicionar
							</p>
						</button>
					</div>
				</header>

				<div
					className={`flex max-h-0 flex-col gap-8 rounded-[10px] bg-neutral-100 bg-opacity-60 opacity-0 transition-all duration-500 ${filterIsOpen && "mt-12 max-h-fit p-6 opacity-100"}`}
				>
					{filterIsOpen && (
						<Form {...form}>
							<div className="flex w-full flex-col items-center gap-6 md:flex-row">
								<div
									className={`relative w-full ${
										help.length > 0 ? "text-neutral-600" : "text-neutral-200"
									} focus-within:text-neutral-1100`}
								>
									<Input
										placeholder="Pesquise por uma dúvida"
										className={`h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:!text-P5  ${
											help.length > 0
												? "placeholder:text-neutral-600"
												: "placeholder:text-neutral-200"
										}`}
										{...form.register("name")}
									/>
									<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

									{form.watch("name") !== undefined &&
										form.watch("name") !== "" && (
											<button
												className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-200 text-neutral-1100"
												onClick={() => form.setValue("name", undefined)}
											>
												<CloseIcon />
											</button>
										)}
								</div>

								<FormField
									control={form.control}
									name="topic"
									render={({ field }) => (
										<FormItem className="w-full">
											<Select
												onValueChange={(e) => {
													field.onChange(e);
												}}
												defaultValue={field.value}
												value={field.value}
											>
												<FormControl>
													<SelectTrigger className="w-full">
														<SelectValue placeholder="Selecione um assunto" />
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{topics.map((topic) => (
														<SelectItem key={topic.id} value={topic.id}>
															{topic.name}
														</SelectItem>
													))}
												</SelectContent>
											</Select>
										</FormItem>
									)}
								/>
							</div>

							<div className="flex flex-col justify-between gap-6 md:flex-row md:items-center">
								<div>
									<span className="font-inter text-P5 font-normal leading-160 text-neutral-400">
										Nenhum filtro aplicado
									</span>
								</div>

								<div className="flex gap-4">
									<Button
										className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] bg-transparent font-inter !text-P6 font-semibold leading-160 text-neutral-600 transition-all duration-500 hover:bg-neutral-200 disabled:bg-transparent disabled:text-neutral-400"
										variant="secondary"
										onClick={() => {
											form.setValue("name", undefined);
											form.setValue("topic", undefined);
											setFilterIsOpen(false);
										}}
									>
										Limpar filtro
									</Button>
									<Button
										className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] font-inter !text-P6 font-semibold leading-160"
										onClick={form.handleSubmit(async (data) => {
											handleFilterSubmit(data);
										})}
									>
										Aplicar
									</Button>
								</div>
							</div>
						</Form>
					)}
				</div>

				<div className={filterIsOpen ? "mt-12" : "mt-12"}>
					<div className="flex flex-col gap-4 pb-8">
						{!isLoading &&
							help.map((help) => (
								<div
									className="flex flex-col gap-8 rounded-xl border border-solid border-neutral-100 p-6"
									key={help.id}
								>
									<div className="flex items-end justify-between gap-2">
										<div className="flex flex-col justify-center gap-1">
											<span className="font-inter text-P7 font-medium leading-160 text-neutral-600">
												Assunto
											</span>

											<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
												{help.name}
											</p>
										</div>

										<span className="font-inter text-P5 font-normal leading-160 text-neutral-600">
											{help.questions.length === 0
												? "Nenhuma dúvida adicionada"
												: `${help.questions.length < 10 ? `0${help.questions.length}` : help.questions.length} ${
														help.questions.length === 1
															? "dúvida adicionada"
															: "dúvidas adicionadas"
													}`}
										</span>
									</div>

									{help.questions.length > 0 && (
										<div className="flex flex-col gap-4">
											{help.questions.map((question) => (
												<AdminQuestionCard
													content={question.answer}
													key={question.id}
													name={question.question}
													onEdit={() => {
														setCurrentQuestion({
															id: question.id,
															question: question.question,
															answer: question.answer,
															topic_id: help.id,
														});
														setModalIsOpen(true);
													}}
													onRemove={() => {
														handleDeleteQuestion(
															question.id,
															question.question,
														);
													}}
												/>
											))}
										</div>
									)}
								</div>
							))}
					</div>

					{!isLoading && help.length === 0 && (
						<div className="flex h-full w-full flex-1 flex-col items-center justify-center gap-6 py-32">
							<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
								<SearchIcon />
							</span>

							<div className="flex flex-col items-center gap-2">
								<h3 className="text-center font-inter text-P2 font-medium leading-140 text-neutral-1100">
									No momento você não possui nenhuma dúvida adicionada
								</h3>

								<p className="text-center font-inter text-P3 font-normal leading-160 text-neutral-500">
									Adicione assuntos e dúvidas para visualizar e gerenciar.
								</p>
							</div>
						</div>
					)}
				</div>
			</div>

			{mentorModalIsOpen && (
				<AdminHelpEditTopics
					title="Editar assuntos"
					closeModal={() => setMentorModalIsOpen(false)}
				/>
			)}

			{modalIsOpen && (
				<AdminHelpAddQuestion
					onEditOrSave={() => {
						fetchHelp();
						setModalIsOpen(false);
						setCurrentQuestion(null);
					}}
					closeModal={() => {
						setModalIsOpen(false);
						setCurrentQuestion(null);
					}}
					currentQuestion={currentQuestion}
					isEditing={!!currentQuestion}
					topics={topics}
				/>
			)}

			{deleteModal && (
				<DeleteModal
					title="Deseja realmente excluir essa dúvida?"
					description={`Essa ação não poderá ser desfeita.`}
					icon="warning.svg"
					confirmText="Excluir dúvida"
					isLoading={isLoading}
					cancelAction={() => setDeleteModal(false)}
					confirmAction={confirmDelete}
					closeModal={() => setDeleteModal(false)}
				/>
			)}

			{successDeleteModal && (
				<AlertModal
					title="Dúvida excluída!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessDeleteModal(false);
					}}
					closeModal={() => {
						setSuccessDeleteModal(false);
					}}
				/>
			)}
		</>
	);
}
