import { AxiosError } from "axios";

import { api } from "@/lib/axios";

import {
	DeleteHelpQuestionError,
	DeleteHelpQuestionResponse,
} from "./help-topics";

export async function deleteHelpQuestion(
	hash: string,
): Promise<
	| { status: "success"; data: DeleteHelpQuestionResponse }
	| { status: "error"; errors: DeleteHelpQuestionError }
> {
	try {
		const response = await api.delete<DeleteHelpQuestionResponse>(
			`/help-question/delete/${hash}`,
		);

		return {
			status: "success",
			data: response.data,
		};
	} catch (error) {
		const axiosError = error as AxiosError<DeleteHelpQuestionError>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: {
					message: axiosError.response.data.message,
					errors: axiosError.response.data.errors || {},
				},
			};
		}

		return {
			status: "error",
			errors: {
				message: "An unknown error occurred. Please try again later.",
				errors: {
					unknown: ["An unknown error occurred. Please try again later."],
				},
			},
		};
	}
}
