import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { fetchHelpTopics } from "@/api/admin/help/fetch-help-topics";
import { fetchHelpTopicsList } from "@/api/admin/help/fetch-help-topics-list";
import type {
	HelpQuestion,
	HelpTopicListItem,
} from "@/api/admin/help/help-topics";
import { Skeleton } from "@/components/ui/skeleton";
import { useAuthStore } from "@/store/auth";
import { useNavigationStore } from "@/store/navigation-store";

import { HelpCards } from "./cards";
import { QuestionDetail } from "./details";
import { HelpHeader } from "./header";
import { QuestionList } from "./list";
import { HelpNavigation } from "./navigation";
import { HelpSearch } from "./search";

export function Help() {
	const { profile } = useAuthStore();
	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	const [topics, setTopics] = useState<HelpTopicListItem[]>([]);
	const [currentTopic, setCurrentTopic] = useState<HelpTopicListItem | null>(
		null,
	);
	const [questions, setQuestions] = useState<HelpQuestion[]>([]);
	const [currentQuestion, setCurrentQuestion] = useState<HelpQuestion | null>(
		null,
	);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!profile) return;

		const paths = [
			{
				name: "Ajuda",
				path: "/help",
			},
		];

		setPaths(paths);
	}, [profile, setPaths]);

	useEffect(() => {
		async function fetchTopics() {
			setIsLoading(true);
			try {
				const response = await fetchHelpTopicsList();

				if (response.status === "success") {
					setTopics(response.data.data);

					if (response.data.data.length > 0) {
						setCurrentTopic(response.data.data[0]);
					}
				}
			} catch (error) {
				console.error("Error fetching topics:", error);
			} finally {
				setIsLoading(false);
			}
		}

		fetchTopics();
	}, []);

	useEffect(() => {
		if (!currentTopic) return;

		async function fetchQuestions() {
			setIsLoading(true);
			try {
				const response = await fetchHelpTopics({
					matter: currentTopic?.id,
				});

				if (response.status === "success") {
					if (
						Array.isArray(response.data.data) &&
						response.data.data.length > 0
					) {
						setQuestions(response.data.data[0].questions);
					}
				}
			} catch (error) {
				console.error("Error fetching questions:", error);
			} finally {
				setIsLoading(false);
			}
		}

		fetchQuestions();
	}, [currentTopic]);

	const handleSearch = async (search: string) => {
		setIsLoading(true);
		try {
			const response = await fetchHelpTopics({
				title: search,
			});

			if (response.status === "success") {
				if (
					Array.isArray(response.data.data) &&
					response.data.data.length > 0
				) {
					setQuestions(response.data.data[0].questions);
				} else {
					setQuestions([]);
				}
			}
		} catch (error) {
			console.error("Error searching questions:", error);
			setQuestions([]);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Helmet title="Ajuda" />

			<div className="flex flex-col gap-14">
				{!currentQuestion && (
					<>
						<HelpHeader profile={profile} />
						<HelpCards />
						<hr className="h-px border-t-neutral-100" />
					</>
				)}

				<section className="flex flex-col gap-12">
					{isLoading ? (
						<div className="flex flex-col gap-8">
							<Skeleton className="h-10 w-2/4" />
							<Skeleton className="h-10 w-full" />
							<div className="flex flex-col justify-between gap-24 md:flex-row">
								<div className="flex w-72 flex-col gap-4">
									<Skeleton className="h-8 w-full" />
									<Skeleton className="h-8 w-full" />
									<Skeleton className="h-8 w-full" />
								</div>
								<div className="flex w-full flex-col gap-6">
									<Skeleton className="h-12 w-full" />
									<Skeleton className="h-12 w-full" />
									<Skeleton className="h-12 w-full" />
								</div>
							</div>
						</div>
					) : (
						<>
							{!currentQuestion && (
								<>
									<HelpSearch onSearch={handleSearch} />
									<div className="flex flex-col justify-between gap-24 md:flex-row">
										<HelpNavigation
											topics={topics}
											currentTopic={currentTopic}
											setCurrentTopic={setCurrentTopic}
										/>

										<QuestionList
											questions={questions}
											setCurrentQuestion={setCurrentQuestion}
										/>
									</div>
								</>
							)}

							{currentQuestion && (
								<QuestionDetail
									setCurrentQuestion={setCurrentQuestion}
									currentQuestion={currentQuestion}
									allQuestions={questions}
								/>
							)}
						</>
					)}
				</section>
			</div>
		</>
	);
}
