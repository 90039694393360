import GroupIcon from "@/assets/group-2.svg?react";
import GroupChatIcon from "@/assets/group-chat.svg?react";
import { Card } from "@/components/card";

export function HelpCards() {
	return (
		<section className="flex flex-col gap-2.5 lg:flex-row lg:gap-8">
			<Card
				title="Suporte"
				description="Entre em contato com nosso time para mais informações."
				to="https://tinyurl.com/Suportefy"
				linkName="Acessar"
				external
			>
				<GroupChatIcon />
			</Card>
			<Card
				title="Telegram"
				description="Fique por dentro das novidades e avisos em nosso canal."
				to="https://t.me/+2_3HjdWMEPNjMWMx"
				linkName="Acessar"
				external
			>
				<GroupIcon />
			</Card>
		</section>
	);
}
