import { ReactNode } from "react";
import { Link } from "react-router-dom";

import ChevronRightIcon from "@/assets/chevron-right.svg?react";

interface CardProps {
	title: string;
	description: string;
	children?: ReactNode;
	to: string;
	linkName?: string;
	external?: boolean;
}

export function Card({
	title,
	description,
	to,
	children,
	external = false,
	linkName = "Saber mais",
}: CardProps) {
	return (
		<div className="flex w-full items-start gap-6 rounded-xl bg-neutral-100 bg-opacity-40 p-6">
			{children && (
				<div className="flex h-12 w-12 items-center justify-center rounded-[10px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-3 text-primary-600">
					{children}
				</div>
			)}

			<div className="flex flex-col gap-8">
				<div className="flex flex-col gap-1">
					<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
						{title}
					</p>

					<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
						{description}
					</span>
				</div>

				{external && (
					<a
						href={to}
						target="_blank"
						rel="noreferrer"
						className="flex cursor-pointer gap-1 font-inter text-P5 font-medium leading-160 text-primary-600 transition-all duration-300 hover:text-neutral-1100 hover:underline hover:underline-offset-8"
					>
						<p>{linkName}</p>
						<ChevronRightIcon width={20} height={20} />
					</a>
				)}

				{!external && (
					<Link
						to={to}
						className="flex cursor-pointer gap-1 font-inter text-P5 font-medium leading-160 text-neutral-600 transition-all duration-300 hover:text-neutral-1100 hover:underline hover:underline-offset-8"
					>
						<p>{linkName}</p>
						<ChevronRightIcon width={20} height={20} />
					</Link>
				)}
			</div>
		</div>
	);
}
