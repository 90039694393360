import { AxiosError } from "axios";

import { api } from "@/lib/axios";

import {
	UpdateHelpTopicError,
	UpdateHelpTopicRequest,
	UpdateHelpTopicResponse,
} from "./help-topics";

export async function updateHelpTopic(
	hash: string,
	data: UpdateHelpTopicRequest,
): Promise<
	| { status: "success"; data: UpdateHelpTopicResponse }
	| { status: "error"; errors: UpdateHelpTopicError }
> {
	try {
		const response = await api.put<UpdateHelpTopicResponse>(
			`/help-topic/update/${hash}`,
			data,
		);

		return {
			status: "success",
			data: response.data,
		};
	} catch (error) {
		const axiosError = error as AxiosError<UpdateHelpTopicError>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data,
			};
		}

		return {
			status: "error",
			errors: {
				message: "An unknown error occurred. Please try again later.",
				errors: {
					unknown: ["An unknown error occurred. Please try again later."],
				},
			},
		};
	}
}
