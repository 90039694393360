import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import type {
	HelpQuestion,
	HelpTopicListItem,
} from "@/api/admin/help/help-topics";
import { saveHelpQuestion } from "@/api/admin/help/save-help-question";
import { updateHelpQuestion } from "@/api/admin/help/update-help-question";
import { AlertModal } from "@/components/alert-modal";
import { Modal } from "@/components/modal";
import { TextEditor } from "@/components/text-editor";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form, FormField, FormItem } from "@/components/ui/form";
import {
	FloatingLabelSelect,
	SelectContent,
	SelectItem,
} from "@/components/ui/select";

interface HelpQuestionForm extends HelpQuestion {
	topic_id: string;
}

interface AdminHelpAddQuestionProps {
	topics: HelpTopicListItem[];
	isEditing: boolean;
	currentQuestion: HelpQuestionForm | null;
	onEditOrSave: VoidFunction;
	closeModal: VoidFunction;
}

export function AdminHelpAddQuestion({
	topics,
	isEditing,
	currentQuestion,
	onEditOrSave,
	closeModal,
}: AdminHelpAddQuestionProps) {
	const [successModal, setSuccessModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const filter = z.object({
		id: z.string().optional(),
		topic_id: z.string().min(1, { message: "Campo obrigatório" }),
		question: z.string().min(1, { message: "Campo obrigatório" }),
		answer: z.string().min(1, { message: "Campo obrigatório" }),
	});

	type Filter = z.infer<typeof filter>;

	const form = useForm<Filter>({
		resolver: zodResolver(filter),
		defaultValues: {
			id: currentQuestion?.id,
			topic_id: currentQuestion?.topic_id,
			question: currentQuestion?.question,
			answer: currentQuestion?.answer,
		},
	});

	async function handleSubmit(data: Filter) {
		setIsLoading(true);
		setErrorMessage(null);

		try {
			if (isEditing && currentQuestion?.id) {
				const result = await updateHelpQuestion(currentQuestion.id, {
					answer: data.answer,
					help_topic_id: data.topic_id,
					question: data.question,
				});
				if (result.status === "success") {
					setSuccessModal(true);
				} else {
					setErrorMessage(result.errors.message);
				}
			} else {
				const result = await saveHelpQuestion({
					answer: data.answer,
					help_topic_id: data.topic_id,
					question: data.question,
				});
				if (result.status === "success") {
					setSuccessModal(true);
				} else {
					setErrorMessage(result.errors.message);
				}
			}
		} catch (error) {
			setErrorMessage("An unexpected error occurred. Please try again.");
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<>
			<Modal
				title={isEditing ? "Editar dúvida" : "Nova dúvida"}
				closeModal={() => {
					closeModal();
					form.reset();
				}}
				confirmAction={form.handleSubmit(handleSubmit)}
				confirmText={isEditing ? "Salvar" : "Adicionar"}
				isLoading={isLoading}
				cancelAction={() => {
					closeModal();
					form.reset();
				}}
			>
				<Form {...form}>
					<div className="flex flex-col gap-8">
						<FormField
							control={form.control}
							name="topic_id"
							render={({ field }) => (
								<FormItem className="w-full">
									<FloatingLabelSelect
										onValueChange={(value) => {
											console.log(value);
											field.onChange(value);
										}}
										value={field.value}
										label="Selecione um assunto"
									>
										<SelectContent>
											{topics.map((topic) => (
												<SelectItem key={topic.id} value={topic.id}>
													{topic.name}
												</SelectItem>
											))}
										</SelectContent>
									</FloatingLabelSelect>

									{form.formState.errors.topic_id && (
										<p className="font-inter text-P6 font-normal leading-160 text-red-600">
											{form.formState.errors.topic_id.message}
										</p>
									)}
								</FormItem>
							)}
						/>

						<div className="flex w-full flex-col items-center gap-6">
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="question"
									type="text"
									label="Título da sua dúvida"
									bgColor="bg-neutral-100"
									hasError={!!form.formState.errors.question}
									{...form.register("question")}
								/>
								{form.formState.errors.question && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.question.message}
									</p>
								)}
							</div>
						</div>

						<div className="flex w-full flex-col gap-2">
							<TextEditor
								initialContent={form.getValues("answer")}
								onChange={(content) => form.setValue("answer", content)}
								placeholder="Escreva a resposta da sua dúvida..."
							/>

							{form.formState.errors.answer && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{form.formState.errors.answer.message}
								</p>
							)}
						</div>
					</div>
				</Form>

				{errorMessage && (
					<p className="mt-4 text-center text-red-600">{errorMessage}</p>
				)}
			</Modal>

			{successModal && (
				<AlertModal
					title={
						isEditing
							? "Alterações salvas com sucesso!"
							: "Dúvida adicionada com sucesso!"
					}
					icon="done.svg"
					confirmText="Concluir"
					confirmAction={() => {
						setSuccessModal(false);
						form.setValue("question", "");
						form.setValue("answer", "");
						form.setValue("topic_id", "");
						form.setValue("id", "");
						onEditOrSave();
						closeModal();
					}}
					hasCancel={!isEditing}
					cancelText="Adicionar mais dúvidas"
					cancelAction={() => {
						setSuccessModal(false);
						form.setValue("question", "");
						form.setValue("answer", "");
						form.setValue("topic_id", "");
						form.setValue("id", "");
					}}
					closeModal={() => setSuccessModal(false)}
				/>
			)}
		</>
	);
}
