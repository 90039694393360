import type { HelpTopicListItem } from "@/api/admin/help/help-topics";
import ChevronRightIcon from "@/assets/chevron-right.svg?react";

interface HelpNavigationProps {
	topics: HelpTopicListItem[];
	currentTopic: HelpTopicListItem | null;
	setCurrentTopic: (topic: HelpTopicListItem | null) => void;
}

export function HelpNavigation({
	topics,
	currentTopic,
	setCurrentTopic,
}: HelpNavigationProps) {
	const handleTopicClick = (topic: HelpTopicListItem) => {
		if (currentTopic?.id === topic.id) return;

		setCurrentTopic(topic);
	};

	return (
		<section className="sticky top-32 flex h-fit min-w-72 flex-col gap-8 rounded-xl bg-neutral-100 bg-opacity-40 p-6">
			<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
				Navegue por assuntos
			</p>

			<nav className="flex flex-col gap-4">
				{topics.map((topic) => (
					<ListItem
						key={topic.id}
						name={topic.name}
						onClick={() => handleTopicClick(topic)}
						isActive={currentTopic?.id === topic.id}
						disabled={false}
					/>
				))}
			</nav>
		</section>
	);
}

interface ListItemProps {
	name: string;
	onClick: () => void;
	isActive: boolean;
	disabled: boolean;
}

function ListItem({ name, onClick, isActive, disabled }: ListItemProps) {
	return (
		<button
			onClick={onClick}
			disabled={disabled}
			className={`flex w-full items-center justify-between rounded-[8px] px-2 py-3 text-left text-neutral-400 transition-all duration-300 hover:bg-neutral-200 hover:text-white ${
				isActive && "bg-neutral-200 !text-white"
			} ${disabled && "cursor-not-allowed !bg-neutral-200 !text-neutral-200"}
      `}
		>
			<div className={`flex flex-1 items-center gap-3`}>
				<p className="font-inter text-P5 font-medium leading-160">{name}</p>
			</div>

			{!disabled && <ChevronRightIcon width={20} height={20} />}
		</button>
	);
}
