import { AxiosError } from "axios";

import { api } from "@/lib/axios";

import type { HelpTopicsRequest, HelpTopicsResponse } from "./help-topics";

export async function fetchHelpTopics(params: HelpTopicsRequest = {}): Promise<
	| {
			status: "success";
			data: HelpTopicsResponse;
	  }
	| { status: "error"; errors: { [key: string]: string[] } }
> {
	try {
		const { data } = await api.get<{ data: HelpTopicsResponse }>(
			"/help-topic",
			{
				params,
			},
		);

		return {
			status: "success",
			data: data.data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			message: string;
			errors: { [key: string]: string[] };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: ["Erro desconhecido. Tente novamente mais tarde."],
			},
		};
	}
}
